import React from "react";
import Thgastos from "./Thgastos";

const AbionosDia = React.forwardRef((props, ref) => {
	const { abonosDelDia, corte, total, fechaV, suc, gastos, notas } = props;
	const entregas = notas.filter((row) => {
		if (row.mpago === "entrega") {
			return true;
		}
		return false;
	});
	let abototal = 0;
	abonosDelDia.forEach((abo) => {
		if (abo.status !== "cancelada") {
			abototal += parseFloat(abo.monto);
		}
	});
	let cotratotal = 0;
	entregas.forEach((abo) => {
		if (abo.status !== "cancelada") {
			cotratotal += parseFloat(abo.monto - abo.abonos);
		}
	});
	console.log(gastos);
	console.log(notas);
	console.log(entregas);

	return (
		<div ref={ref} className='pdf colprint'>
			<h2 className='hidetprint'>Corte al Día {fechaV}</h2>
			<table className='m0 w-100'>
				<thead>
					<tr>
						<th>Tipo</th>
						<th>monto</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(corte).map((key, index) => (
						<tr className='tdlink' style={{ cursor: "auto" }} key={index}>
							<td>{key}</td>
							<td>${corte[key]}</td>
						</tr>
					))}
					<tr>
						<td>
							<h3>Total del dia</h3>
						</td>
						<td>
							<strong>$ {total}</strong>
						</td>
					</tr>
				</tbody>
			</table>
			<h2>Abonos del Dia en: {suc.charAt(0).toUpperCase() + suc.slice(1)}</h2>
			<table className='my-1 w-100 h-100p'>
				<thead>
					<tr>
						<th>Nota</th>
						<th>Cliente</th>
						<th>Tipo</th>
						<th>monto</th>
					</tr>
				</thead>
				<tbody>
					{abonosDelDia.map((abo, i) =>
						abo.status !== "cancelada" ? (
							<tr className='tdlink' style={{ cursor: "auto" }} key={i}>
								<td className={abo.status === 0 ? "cancelada" : null}>{abo.nota}</td>
								<td className={abo.status === 0 ? "cancelada" : null}>{abo.cliente}</td>
								<td className={abo.status === 0 ? "cancelada" : null}>{abo.tipo}</td>
								<td className={abo.status === 0 ? "cancelada" : null}>{abo.monto}</td>
							</tr>
						) : null
					)}
					<tr>
						<td colSpan={2}>
							<h3>Total de Abonos</h3>
						</td>
						<td colSpan={2}>
							<strong>$ {abototal}</strong>
						</td>
					</tr>
				</tbody>
			</table>
			<h2>Contra Entregas</h2>
			<table className='my-1 w-100'>
				<thead>
					<tr>
						<th>Nota</th>
						<th>Cliente</th>
						<th>Restante</th>
					</tr>
				</thead>
				<tbody>
					{entregas.map((abo, i) =>
						abo.status !== "cancelada" ? (
							<tr className='tdlink' style={{ cursor: "auto" }} key={i}>
								<td>{abo.folio}</td>
								<td>{abo.cliente}</td>
								<td className='success'>${abo.monto - abo.abonos}</td>
							</tr>
						) : null
					)}
					<tr>
						<td colSpan={2}>
							<h3>Total de Contra Entregas</h3>
						</td>
						<td>
							<strong>$ {cotratotal}</strong>
						</td>
					</tr>
				</tbody>
			</table>
			<Thgastos datos={gastos} fecha={fechaV} suc={suc} />
		</div>
	);
});

export default AbionosDia;
