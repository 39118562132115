import React, { useContext, useEffect } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import Spinner from "../estructura/Spinner.js";
import Tdcuenta from "./Tdcuenta.js";

const Thcuenta = () => {
	const cuentaContext = useContext(CuentaContext);
	const { traerCuentas, cuentas } = cuentaContext;
	useEffect(() => {
		if (cuentas.length === 0) {
			traerCuentas();
		}
		//eslint-disable-next-line
	}, [cuentas]);
	console.log(cuentas);

	if (cuentas.length === 0 || cuentas[0] === "null") {
		return <Spinner />;
	} else {
		return (
			<table className='my-1 w-100'>
				<thead>
					<tr>
						<th>Folio</th>
						<th>Nombre</th>
						<th>Vence</th>
						<th>Monto</th>
						<th>CNL</th>
					</tr>
				</thead>
				<tbody>
					{cuentas.map((cta) => (
						<Tdcuenta key={cta.folio} cuenta={cta} />
					))}
				</tbody>
			</table>
		);
	}
};

export default Thcuenta;
