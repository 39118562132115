import React, { useContext, useState, useEffect } from "react";
import ProdContext from "../../contexto/producto/prodContext.js";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import ClienteContext from "../../contexto/cliente/clienteContext.js";
import AutContext from "../../contexto/autentificacion/autContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import InvContext from "../../contexto/inventario/invContext.js";

const NuevaCuenta = () => {
	///////////////////////////
	// Contextos
	const prodContext = useContext(ProdContext);
	const cuentaContext = useContext(CuentaContext);
	const autContext = useContext(AutContext);
	const alertContext = useContext(AlertContext);
	const clienteContext = useContext(ClienteContext);
	const { disponibles, traerProductosDisp } = prodContext;
	const { crearNota, unsetCuentas } = cuentaContext;
	const { refreshSession } = autContext;
	const { traerClientes, clientes } = clienteContext;
	const { setAlert } = alertContext;
	const { invChk } = useContext(InvContext);
	///////////////////////////
	// states
	const today = new Date().toISOString().split("T")[0];

	const addMonths = (date, months) => {
		const d = new Date(date);
		d.setMonth(d.getMonth() + months);
		return d.toISOString().split("T")[0];
	};

	const hoy = today;
	const unMesAdelante = addMonths(today, 1);
	const dosMesesAdelante = addMonths(today, 2);
	const tresMesesAdelante = addMonths(today, 3);

	const [usuario, setUsuario] = useState("");
	const [direccion, setDireccion] = useState("");
	const [ciudad, setCiudad] = useState("");
	const [tel, setTel] = useState("");
	const [ref, setRef] = useState("");
	const [mpago, setMpago] = useState("contado");
	const [sucursal, setSucursal] = useState("");
	const [vendedor, setVendedor] = useState("");
	const [producto, setProducto] = useState("");
	const [prodId, setProdId] = useState("");
	const [cant, setCant] = useState(1);
	const [precio, setPrecio] = useState(0);
	const [prodSug, setProdSug] = useState([]);
	const [conceptos, setConceptos] = useState([]);
	const [regmov, setRegmov] = useState([]);
	const [venci, setVenci] = useState(hoy);

	const onChangeProd = (e) => {
		let Pmatches = [];
		if (disponibles.length > 0) {
			Pmatches = disponibles.filter((prod) => {
				const pregex = new RegExp(`${producto}`, "gi");
				return prod.Nombre.match(pregex);
			});
		}
		setProdSug(Pmatches);
		setProducto(e.target.value);
	};
	const removeConcepto = (index) => {
		const temp = [...conceptos];
		temp.splice(index, 1);
		setConceptos(temp);
		const temp2 = [...regmov];
		temp.splice(index, 1);
		setRegmov(temp2);
	};
	const addConcepto = (e) => {
		e.preventDefault();
		const prodname = disponibles.find((row) => row.Nombre === producto);
		if (!prodname) {
			setAlert("El nobre del producto no coincide seleccione de nuevo", "danger");
			return;
		}
		if (prodId === "") {
			setAlert("El producto no se encuentra en el catálogo", "danger");
			return;
		}
		if (usuario.length === 0) {
			setAlert("Ingrese un Cliente", "danger");
			return;
		}
		setConceptos([
			...conceptos,
			{
				producto_id: prodId,
				producto: producto,
				cantidad: cant,
				precio: precio,
			},
		]);
		setRegmov([
			...regmov,
			{
				producto: prodId,
				cantidad: parseInt(cant),
			},
		]);
		setProducto("");
		setProdId("");
	};

	const onSugProdClick = (Nombre, id) => {
		setProducto(Nombre);
		setProdId(id);
		setProdSug([]);
	};
	/////////////////////////////
	//enchange
	const onChangeUsuario = (e) => setUsuario(e.target.value);
	const onChangeDireccion = (e) => setDireccion(e.target.value);
	const onChangeCiudad = (e) => setCiudad(e.target.value);
	const onChangeTel = (e) => setTel(e.target.value);
	const onChangeRef = (e) => setRef(e.target.value);
	const onChangeMpago = (e) => setMpago(e.target.value);
	const onChangeSuc = (e) => setSucursal(e.target.value);
	const onChangeVende = (e) => setVendedor(e.target.value);
	const onChangeCant = (e) => setCant(e.target.value);
	const onChangePrecio = (e) => setPrecio(e.target.value);
	const onChangeVenci = (e) => setVenci(e.target.value);

	////////////////////////////
	// crearNuevaNota
	const crearNuevaNota = async () => {
		if (usuario.length === 0) {
			setAlert("No agregó un Cliente a la nota", "danger");
			return;
		}
		if (sucursal === "") {
			setAlert("No Seleccionó sucursal", "danger");
			return;
		}
		const confirmar = prompt("Ingrese su clave");
		if (confirmar == "1234") {
			setAlert("Clave correcta", "success");
		} else {
			setAlert("Clave invalida", "danger");
			return;
		}
		/////////////////////////////////////////////
		//procesamiento de nota con cambios de fer
		//eliminar este bloque si se regresa a la version anterior
		const aut = await refreshSession();
		const body = {
			cliente: usuario,
			dir: direccion,
			ciudad: ciudad,
			tel: tel,
			ref: ref,
			mpago: mpago,
			conceptos: conceptos,
			vendedor: vendedor,
			sucursal: sucursal,
			vencimiento: venci,
		};
		if (aut) {
			const resp = await crearNota(body);
			console.log(resp);

			resp.success && unsetCuentas();
			setConceptos([]);
			setUsuario("");
		} else {
			setAlert("Error ingrese de nuevo", "danger");
		}

		//////////////////////////////////////////////
		//Bloque eliminado a peticion de Fer se elimina el chequeo de inventario y el registro de salida automatico
		/*
		const body = {
			productos: regmov,
		};
		let stkchk = await invChk(body);
		console.log(stkchk);

		if (stkchk.success) {
			const loc = sucursal === "centro" ? 3 : sucursal === "salida" ? 2 : 4;
			const aut = await refreshSession();
			const body = {
				cliente: usuario,
				dir: direccion,
				ciudad: ciudad,
				tel: tel,
				ref: ref,
				mpago: mpago,
				conceptos: conceptos,
				vendedor: vendedor,
				sucursal: sucursal,
			};
			if (aut) {
				const resp = await crearNota(body);
				console.log(resp);

				resp.success && unsetCuentas();
				setConceptos([]);
				setUsuario("");
				//movimiento Automático cancelado
				/* 
				if (resp.success) {
					const resp2 = await invRegistroDir(
						"salida",
						loc,
						resp.data.folio,
						"Movimiento automático",
						regmov
					);
					console.log(22);

					console.log(resp2);
					if (!resp2.success) {
						setAlert("Movimento de inventario no pudo ser creado", "danger");
					} else {
						setRegmov([]);
					}
				}
			} else {
				setAlert("Error ingrese de nuevo", "danger");
			}
		} else {
			setAlert(stkchk.data.messages[0], "danger");
		}*/
	};
	/////////////////////////////
	// useEffect
	useEffect(() => {
		if (clientes.length == 0) {
			traerClientes();
		}
		if (disponibles.length == 0) {
			traerProductosDisp();
		}
	}, [clientes, disponibles, prodSug, conceptos, regmov]);
	let total = 0;
	conceptos.map((row) => {
		total = total + parseFloat(row.cantidad) * parseFloat(row.precio);
	});
	return (
		<div id='notas'>
			<h2>Nueva Nota</h2>
			<form>
				<div className='grid-2 gapMed'>
					<article>
						<label htmlFor='cliente'>Nombre.</label>
						<input
							type='text'
							name='cliente'
							value={usuario}
							onChange={onChangeUsuario}
							className='bigimput m0'
						/>
					</article>
					<article>
						<label htmlFor='dir'>Dirección.</label>
						<input
							type='text'
							name='dir'
							value={direccion}
							onChange={onChangeDireccion}
							className='bigimput m0'
						/>
					</article>
					<article>
						<label htmlFor='dir'>Ciudad.</label>
						<input
							type='text'
							name='ciudad'
							value={ciudad}
							onChange={onChangeCiudad}
							className='bigimput m0'
						/>
					</article>
					<article>
						<label htmlFor='tel'>Tel.</label>
						<input
							type='number'
							name='tel'
							value={tel}
							onChange={onChangeTel}
							className='bigimput m0'
						/>
					</article>
					<article>
						<label htmlFor='vendor'>Vendedor</label>
						<input
							type='text'
							name='vendedor'
							value={vendedor}
							onChange={onChangeVende}
							className='bigimput m0'
						/>
					</article>
					<article>
						<label htmlFor='ref'>Referencia.</label>
						<input
							type='text'
							name='ref'
							value={ref}
							onChange={onChangeRef}
							className='bigimput m0'
						/>
					</article>

					<article>
						<label htmlFor='tel'>Metodo Pago.</label>
						<select name='' id='' onChange={onChangeMpago}>
							<option value='contado'>Contado</option>
							<option value='credito'>Credito</option>
							<option value='apartado'>Apartado</option>
							<option value='entrega'>Contra Entrega</option>
						</select>
					</article>
					<article>
						<label htmlFor='tel'>Sucursal</label>
						<select name='' id='' onChange={onChangeSuc}>
							<option value=''>------</option>
							<option value='centro'>Centro</option>
							<option value='salida'>Salida SLP</option>
							<option value='arriaga'>Arriga</option>
						</select>
					</article>
					<article>
						<label htmlFor='venci'>Vencimiento</label>
						<select
							name='venci'
							value={venci}
							onChange={onChangeVenci}
							className='m0'
						>
							<option value={hoy}>Hoy ({hoy})</option>
							<option value={unMesAdelante}>1 Mes ({unMesAdelante})</option>
							<option value={dosMesesAdelante}>2 Meses ({dosMesesAdelante})</option>
							<option value={tresMesesAdelante}>3 Meses ({tresMesesAdelante})</option>
						</select>
					</article>
				</div>
				<div className='grid-8 my-1'>
					<article>
						<label htmlFor='Cant'>Cant.</label>
						<input
							type='number'
							name='cant'
							value={cant}
							onChange={onChangeCant}
							className='bigimput my'
						/>
					</article>
					<article className='gspan-4'>
						<label htmlFor='producto'>Producto.</label>
						<input
							type='text'
							name='producto'
							value={producto}
							onChange={onChangeProd}
							className='bigimput my'
						/>
					</article>
					<article className='gspan-2'>
						<label htmlFor='producto'>Precio Unit.</label>
						<input
							type='number'
							name='precio'
							value={precio}
							onChange={onChangePrecio}
							className='bigimput my'
						/>
					</article>
					<article>
						<label htmlFor='producto'>Añadir.</label>
						<button className='addP my' onClick={addConcepto}>
							+
						</button>
					</article>
				</div>
				<div>
					{prodSug &&
						prodSug.map((prodSug, i) => (
							<div
								className='sug my'
								id={prodSug.id}
								key={i}
								onClick={() => onSugProdClick(prodSug.Nombre, prodSug.id)}
								onBlur={() => {
									setTimeout(() => {
										setProdSug([]);
									}, 100);
								}}
							>
								{prodSug.Nombre}
							</div>
						))}
				</div>
			</form>
			{conceptos.length > 0 && (
				<table className='w-100'>
					<thead>
						<tr className='w-100'>
							<th colSpan={5} className='w-100' style={{ backgroundColor: "#d5d5d5" }}>
								Conceptos
							</th>
						</tr>
						<tr>
							<th>Cant</th>
							<th>Producto</th>
							<th>Precio</th>
							<th>importe</th>
							<th>Elim</th>
						</tr>
					</thead>
					<tbody>
						{conceptos.map((row, i) => {
							return (
								<tr className='tdlink' key={i}>
									<td>{row.cantidad}</td>
									<td>{row.producto}</td>
									<td>${row.precio}</td>
									<td>${row.cantidad * row.precio}</td>
									<td>
										<i
											className='fas fa-times'
											onClick={(x) => {
												removeConcepto(i);
											}}
										></i>
									</td>
								</tr>
							);
						})}
						<tr style={{ backgroundColor: "#ddd" }}>
							<td colSpan={5}>
								<h3 style={{ margin: "0", padding: "0" }}>
									Total:$
									{total}
								</h3>
							</td>
						</tr>
						<tr className='w-100'>
							<td colSpan={5} style={{ margin: "0", padding: "0" }}>
								<button className='w-100 btn btn-dark' onClick={crearNuevaNota}>
									Crear Nota
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</div>
	);
};

export default NuevaCuenta;
