import React, { useContext } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import PropTypes from "prop-types";

const Tdcuenta = ({
	cuenta: { folio, cliente, fecha, monto, status, vencimiento },
}) => {
	const cuentaContext = useContext(CuentaContext);
	const {
		setCuenta,
		cuentas,
		unsetAbonos,
		traerAbonosByNota,
		statusdCuenta,
		unsetCuentas,
		traerCuentaSingle,
	} = cuentaContext;
	const dateRaw = fecha.substring(0, 10);
	const fechaArr = dateRaw.split("-");
	const venciArr =
		typeof vencimiento !== "undefined" && vencimiento !== null
			? vencimiento.split("-")
			: null;
	///////////////////////////
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const ffecha =
		fechaArr[2] + "/" + meses[fechaArr[1].replace(/^0+/, "")] + "/" + fechaArr[0];
	const fvenci = venciArr
		? venciArr[2] +
		  "/" +
		  meses[venciArr[1].replace(/^0+/, "")] +
		  "/" +
		  venciArr[0]
		: null;
	//////////////////////////
	// vencimiento color
	const venCo = (fven) => {
		const today = new Date();
		const vencDate = new Date(fven);
		today.setHours(0, 0, 0, 0);
		vencDate.setHours(0, 0, 0, 0);
		const oneMonthAway = new Date(today);
		oneMonthAway.setMonth(today.getMonth() + 1);

		if (vencDate > oneMonthAway) {
			return 1;
		} else if (vencDate < oneMonthAway && vencDate > today) {
			return 2;
		} else if (vencDate < today) {
			return 3;
		}
		return "";
	};

	///////////////////////////
	const borrarCuenta = async () => {
		const conf = window.confirm("está seguro que Desea Cancelar la nota?");
		if (conf) {
			let body = {
				cuentaid: folio,
				status: "cancelada",
			};
			statusdCuenta(body);
			unsetCuentas();
		}
	};
	///////////////////////////
	// traer nota on click
	const oCtraerNota = async () => {
		const cuenta = await traerCuentaSingle(folio);
		console.log(cuenta);

		if (!cuenta) {
			alert("no hay detalles de esa Cuenta");
		} else {
			unsetAbonos();
			setCuenta(cuenta.data.data[0]);
			traerAbonosByNota(folio);
		}
	};
	return (
		<tr className='tdlink' onClick={oCtraerNota}>
			<td className={status === "cancelada" ? "cancelada" : null}>{folio}</td>
			<td className={status === "cancelada" ? "cancelada" : null}>{cliente}</td>
			<td
				className={`${status === "cancelada" ? "cancelada" : ""} ${
					venCo(vencimiento) == 1
						? "bg-green-300"
						: venCo(vencimiento) == 2
						? "bg-orange-300"
						: venCo(vencimiento) == 3
						? "bg-red-300"
						: ""
				}`}
			>
				{fvenci ? fvenci : "Sin fecha"}
			</td>
			<td className={status === "cancelada" ? "cancelada" : null}>{monto}</td>
			<td>
				{status !== "cancelada" ? (
					<i onClick={borrarCuenta} className='fas fa-trash-alt lead'></i>
				) : null}
			</td>
		</tr>
	);
};
Tdcuenta.propTypes = {
	cuenta: PropTypes.object.isRequired,
};
export default Tdcuenta;
