import React, { useReducer } from "react";
import axios from "axios";
import CuentaContext from "./cuentaContext.js";
import CuentaReducer from "./cuentaReducer.js";
import { CUENTAS, CUENTA, SET_LOADING, ABONOS, GASTOS } from "../types.js";

const CuentaState = (props) => {
	/////////////////////////////
	//se inicializa de state
	const initialState = {
		cuentas: [],
		cuenta: {},
		cuentasLoading: false,
		abonosr: [],
		gastos: [],
	};

	/////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	/////////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(CuentaReducer, initialState);

	/////////////////////////////
	// traer Cuentas
	const traerCuentas = async () => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/cuentas`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			console.log(error.response);

			return error.response;
		}
	};
	/////////////////////////////
	// traer Cuentas pagadas
	const traerCuentasPagadas = async () => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/pagadas/${1}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log(cuentasResp);

			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			console.log(error.response);

			return error.response;
		}
	};
	/////////////////////////////
	// traer Cuentas apartadas
	const traerCuentasApartadas = async () => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/apartadas/${1}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log(cuentasResp);

			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			console.log(error.response);

			return error.response;
		}
	};
	/////////////////////////////
	// traer Cuentas Creditos
	const traerCuentasCreditos = async () => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/creditos/${1}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log(cuentasResp);

			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			console.log(error.response);

			return error.response;
		}
	};
	/////////////////////////////
	// traer Cuentas pagadas
	const traerCuentasEntregadas = async () => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/entregadas/${1}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			console.log(error.response);

			return error.response;
		}
	};

	///////////////////////////////
	//cuentaBusqByNom
	const cuentaBusqByNom = async (nombre) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(
				`/api/cuentas/busqueda/nombre/${nombre}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			console.log(cuentasResp);

			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};
	///////////////////////////////
	//cuentaBusqByCity
	const cuentaBusqByCit = async (ciudad) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(
				`/api/cuentas/busqueda/ciudad/${ciudad}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};

	///////////////////////////////
	//cuentaBusqByNom
	const cuentaBusqByFecha = async (l1, l2) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(
				`/api/cuentas/busqueda/fecha/${l1}/${l2}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (cuentasResp.data.success) {
				//dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};

	//////////////////////////////
	//cuentaBusqById
	const cuentaBusqById = async (id) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/cuentas/busqueda/${id}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log(cuentasResp);
			if (cuentasResp.data.success) {
				dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};
	//////////////////////////////
	//cuentaBusqById
	const traerCuentaSingle = async (id) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/cuentas/busqueda/${id}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log(cuentasResp);
			if (cuentasResp.data.success) {
				dispatch({ type: CUENTA, payload: cuentasResp.data.data[0] });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};
	const setCuenta = (cta) => {
		dispatch({ type: CUENTA, payload: cta });
	};
	const unsetCuenta = () => {
		dispatch({ type: CUENTA, payload: {} });
	};
	const unsetCuentas = () => {
		dispatch({ type: CUENTAS, payload: [] });
	};
	const nullCuentas = () => {
		dispatch({ type: CUENTAS, payload: ["null"] });
	};
	const unsetAbonos = () => {
		dispatch({ type: ABONOS, payload: [] });
	};

	const crearNota = async (body) => {
		const accessToken = await getaccessToken();
		let feedback = {};
		try {
			const resp = await axios.post(`/api/cuentas`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			});
			console.log(resp);

			feedback = {
				success: true,
				data: resp.data.data,
			};
		} catch (err) {
			feedback = {
				success: false,
				payload: err.response,
			};
		}
		return feedback;
	};
	//////////////////////////////////////////
	//Crear abono
	const crearAbono = async (nota, monto, tipo, sucursal) => {
		const accessToken = await getaccessToken();
		let feedback = {};
		try {
			const resp = await axios.post(
				`/api/cuentas/abonos/${nota}/${monto}/${tipo}/${sucursal}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `${accessToken}`,
					},
				}
			);
			feedback = {
				success: true,
				data: resp.data.data,
			};
		} catch (err) {
			feedback = {
				success: false,
				payload: err.response,
			};
		}
		return feedback;
	};
	//////////////////////////////////////////
	//Crear gasto
	const crearGasto = async (body) => {
		const accessToken = await getaccessToken();
		let feedback = {};
		try {
			const resp = await axios.post(`/api/gastos`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			});
			feedback = {
				success: true,
				data: resp.data.data,
			};
		} catch (err) {
			feedback = {
				success: false,
				payload: err.response,
			};
		}
		return feedback;
	};
	///////////////////////////////
	//gastos por fecha
	const gastosBusqByFecha = async (l1, l2) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(`/api/gastos/${l1}/${l2}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (cuentasResp.data.success) {
				console.log(cuentasResp.data.data);

				dispatch({ type: GASTOS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};
	///////////////////////////////////////
	//traer abonbos por nota
	const traerAbonosByNota = async (nota_id) => {
		const accessToken = await getaccessToken();
		let feedback = {};

		try {
			const resp = await axios.get(
				`/api/cuentas/abonos/${nota_id}/${nota_id}/${nota_id}/${nota_id}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			dispatch({ type: ABONOS, payload: resp.data.data });
			console.log(resp.data);
			feedback = {
				success: true,
				data: resp.data.data,
			};
		} catch (err) {
			feedback = {
				success: false,
				payload: err.response,
			};
		}
		return feedback;
	};
	///////////////////////////////
	//abonos por fecha
	const abonosBusqByFecha = async (l1, l2) => {
		const accessToken = getaccessToken();
		try {
			const cuentasResp = await axios.get(
				`/api/abonos/busqueda/fecha/${l1}/${l2}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (cuentasResp.data.success) {
				//dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return cuentasResp;
			}
		} catch (error) {
			return error.response;
		}
	};
	///////////////////////////////
	//abonos por fecha
	const cancelarAbono = async (id) => {
		const accessToken = getaccessToken();
		try {
			const abono = await axios.patch(`/api/abonos/${id}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (abono.data.success) {
				//dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return abono;
			}
		} catch (error) {
			return error.response;
		}
	};
	///////////////////////////////
	//abonos por fecha
	const cancelarGasto = async (id) => {
		const accessToken = getaccessToken();
		try {
			const gasto = await axios.patch(`/api/gastos/${id}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (gasto.data.success) {
				//dispatch({ type: CUENTAS, payload: cuentasResp.data.data });
				return gasto;
			}
		} catch (error) {
			return error.response;
		}
	};
	//////////////////////////////////
	//set Cuenta estatus
	const statusdCuenta = async (body) => {
		const accessToken = await getaccessToken();
		let feedback = {};

		try {
			const resp = await axios.patch(`/api/cuentas/${body.cuentaid}`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			});
			feedback = {
				success: true,
				data: resp.data.data,
			};
		} catch (err) {
			feedback = {
				success: false,
				payload: err.response,
			};
		}
		return feedback;
	};

	//set loading
	const setLoading = () => dispatch({ type: SET_LOADING });
	return (
		<CuentaContext.Provider
			value={{
				cuentas: state.cuentas,
				cuenta: state.cuenta,
				abonosr: state.abonosr,
				gastos: state.gastos,
				setLoading,
				traerCuentas,
				setCuenta,
				unsetCuenta,
				cuentaBusqByNom,
				cuentaBusqByCit,
				cuentaBusqById,
				cuentaBusqByFecha,
				crearNota,
				unsetCuentas,
				nullCuentas,
				crearAbono,
				traerAbonosByNota,
				abonosBusqByFecha,
				unsetAbonos,
				statusdCuenta,
				traerCuentaSingle,
				traerCuentasPagadas,
				traerCuentasApartadas,
				traerCuentasCreditos,
				traerCuentasEntregadas,
				crearGasto,
				gastosBusqByFecha,
				cancelarAbono,
				cancelarGasto,
			}}
		>
			{props.children}
		</CuentaContext.Provider>
	);
};
export default CuentaState;
